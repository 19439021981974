<template>
<div class="container z-depth-1" style="background-color:white">
    <h2 class="center-align black-text">
        <span>Update MPRS Event</span>
    </h2>
    <form @submit.prevent="updateEvent" v-for="(data, index) in datas" :key="index">
        <div class="field event_type">
            <label for="event_type">Event Type:</label>
            <select v-model="data.event_type">
                <option value="">Please select an event</option>
                <option value="APLS">APLS</option>
                <option value="PLS">PLS</option>
            </select>
        </div>

        <div class="field event_name">
            <label for="event_name">Event Name:</label>
            <input type="text" name="event_name" v-model="data.name">
        </div>
        <div class="field start_date">
            <label for="start_date">Start Date:</label>
            <input type="date" name="start_date" v-model="data.start_date">
        </div>
        <div class="field end_date">
            <label for="end_date">End Date:</label>
            <input type="date" name="end_date" v-model="data.end_date">
        </div>
        <div class="field venue">
            <label for="venue">Venue:</label>
            <input type="text" name="venue" v-model="data.venue">
        </div>
        <div class="field start_time">
            <label for="start_time">Start Time:</label>
            <input type="time" name="start_time" v-model="data.start_time">
        </div>
        <div class="field end_time">
            <label for="end_time">End Time:</label>
            <input type="time" name="end_time" v-model="data.end_time">
        </div>
        <div class="field target_participants">
            <label for="target_participants">Target Participants:</label>
            <input type="text" name="target_participants" v-model="data.target_participants">
        </div>
        <div class="field whatsapp_target">
            <label for="whatsapp_target">Whatsapp Target:</label>
            <input type="text" name="whatsapp_target" v-model="data.whatsapp_target">
        </div>
        <div class="field whatsapp_number">
            <label for="whatsapp_number">Whatsapp Number:</label>
            <input type="text" name="whatsapp_number" v-model="data.whatsapp_number">
        </div>
                <div class="field contact_person_1">
            <label for="contact_person_1">Contact Person 1:</label>
            <input type="text" name="contact_person_1" v-model="data.contact_person_1">
        </div>
        <div class="field contact_person_1_phone">
            <label for="contact_person_1_phone">Contact Person 1 Phone Number:</label>
            <input type="text" name="contact_person_1_phone" v-model="data.contact_person_1_phone">
        </div>
        <div class="field contact_person_2">
            <label for="contact_person_2">Contact Person 2:</label>
            <input type="text" name="contact_person_2" v-model="data.contact_person_2">
        </div>
        <div class="field contact_person_2_phone">
            <label for="contact_person_2_phone">Contact Person 2 Phone Number:</label>
            <input type="text" name="contact_person_2_phone" v-model="data.contact_person_2_phone">
        </div>
        <div class="field jointly_organized">
            <label for="jointly_organized">Jointly Organizer: (please use semicolon ";" to seperate each organizer)</label>
            <input type="text" name="jointly_organized" v-model="data.jointly_organized">
        </div>
        <div>
            <label for="invited_instructors">Invited Instructors</label>
            <v-autocomplete
                v-model="data.invited_instructors"
                :items="instructors"
                item-text="name"
                item-value="ID"
                outlined
                dense
                label="Outlined"
                multiple
            ></v-autocomplete>
        </div>
        <div class="field center-align">
            <p v-if="feedback" class="red-text">{{ feedback }}</p>
            <button class="btn blue">
                <span>update event</span>
            </button>
            <br><br>
            <router-link :to="{ name: 'Home' }">
                <button class="btn pink">
                    <span>Back</span>
                </button>
            </router-link>
        </div>
    </form>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: "updateEvent",
    data() {
        return {
            datas: [],
            feedback: null,
            url: "",
            instructors:[]
        };
    },
    methods: {
        updateEvent() {
            const index = 0
            this.url = this.WebUrl + "SearchEvent.php"
            axios.post(this.url, {
                    request: 3,
                    event_type: this.datas[index].event_type,
                    id: this.eventID,
                    name: this.datas[index].name,
                    start_date: this.datas[index].start_date,
                    end_date: this.datas[index].end_date,
                    venue: this.datas[index].venue,
                    start_time: this.datas[index].start_time,
                    end_time: this.datas[index].end_time,
                    target_participants: this.datas[index].target_participants,
                    whatsapp_target: this.datas[index].whatsapp_target,
                    whatsapp_number: this.datas[index].whatsapp_number,
                    jointly_organized:  this.datas[index].jointly_organized,
                    contact_person_1: this.datas[index].contact_person_1,
                    contact_person_1_phone: this.datas[index].contact_person_1_phone,
                    contact_person_2: this.datas[index].contact_person_2,
                    contact_person_2_phone : this.datas[index].contact_person_2_phone,
                    invited_instructors: JSON.stringify(this.datas[index].invited_instructors),
                    user:this.UserEmail
                })
                .then(response => {
                    console.log(response.data);
               
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        searchEvent() {
            this.url = this.WebUrl + "SearchEvent.php"
            axios.post(this.url, {
                    request: 2,
                    id: this.EventID
                })
                .then(response => {
                    //console.log(response.data)
                    this.datas = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        get_instructors(){
            this.url = this.WebUrl + "instructors.php"
            //console.log(this.url)
            axios.post(this.url, {
                    request:1
                })
                .then(response => {
                    this.instructors = response.data;
                   // console.log(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },


    },
    created() {
        this.url = this.WebUrl + "SearchEvent.php"
        this.searchEvent()
        this.get_instructors()
        console.log(this.EventID)
    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        EventID() {
            return this.$store.state.event_id;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },

    }

};
</script>

<style>
select {
    display: block !important
}
</style>
