// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/login-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-bg{box-sizing:border-box;background-color:var(--main);background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat}.login-bg,.login .overlay{position:absolute;top:0;left:0;width:100%;height:100%}.login .overlay{background-color:#fff;opacity:.5}.login-box{position:absolute;top:50%;left:50%;max-width:650px;min-width:500px;transform:translate(-50%,-50%);background-color:hsla(0,0%,100%,.65);padding:30px 50px;z-index:5}.input-box{border:1px solid rgba(0,0,0,.5)!important;border-radius:4px!important;padding:5px 10px!important;width:90%!important;margin:0!important}.input-box::-moz-placeholder{color:#000;opacity:.5}.input-box::placeholder{color:#000;opacity:.5}.input-box:focus{background:#f0f0f0!important;box-shadow:inset 1px 1px 3px 1px #999!important}.field{margin:15px 0}a{text-decoration:none}", ""]);
// Exports
module.exports = exports;
