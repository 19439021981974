<template>
<div>
    <!-- ===================================================== Breadcrumb -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb shadow">
            <li class="breadcrumb-item"><a href=".">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Events</li>
        </ol>
    </nav>
    <div v-for="(data, index) in datas" :key="index">
    <!-- ===================================================== Banner -->
        <div class="page-banner event-1">
            <div class="page-banner-overlay"></div>
            <div class="page-banner-title">
                <h1><b>Events</b></h1>       
                <p><b> {{data.name}} ({{data.event_type}})</b></p>
                <small><b>Date: </b> {{data.start_date}} -  {{data.end_date}}</small>  
                <br>
                <small><b>Time: </b>{{data.start_time}} - {{data.end_time}}</small>
            </div>
        </div>    

        <!-- ===================================================== Venue -->
        <div id="events" class="section-title shadow under-carousel">
            <h1><b>Venue</b></h1>
        </div>
        <div class="container mb-5">
            <div class="row g-2 mx-auto" style="width:98%">
                <div class="col-12">
                    <h4><b>{{data.venue}}</b></h4>    
                </div>

                <!-- ===== Jointly Organized ===== -->
                <div class="col-12" v-if="data.jointly_organized != ''">
                    <hr class="mt-0 mb-5">
                    <b>Jointly Organized:</b>
                    <ul id="jointly-organized">
                        
                        {{data.jointly_organized}}
                    </ul>
                </div>
            </div>
        </div>

        <!-- ===================================================== Participants -->
        <div id="events" class="section-title shadow">
            <h1><b>Target Participants</b></h1>
        </div>
        <div class="participants-bg" v-if="data.target_participants != ''">
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <!-- ===== Participant DIV  -->
                    {{data.target_participants}}
                </div>
            </div>      
        </div>

        <!-- ===================================================== Participants -->
        <div class="footer-contact text-light">
            <div class="container">
                <div class="row">
                    <!-- ===== Contact Person DIV  -->
                    <div class="col-12 col-md-4">
                        <div class="footer-contact-box">
                            <div class="footer-contact-icon mb-5">
                                <h3 class="align-items-center justify-content-center d-flex">
                                    <i class="bi bi-telephone-fill"></i>
                                    <b class="ms-2">Contact</b>
                                </h3>
                            </div>
                            <ul class="footer-contact-list">
                                <li>
                                    <b>{{data.contact_person_1}}</b>
                                    <p>{{data.contact_person_1_phone}}</p>
                                </li>
                                <li v-if="data.contact_person_2 != ''">
                                    <b> {{data.contact_person_2}} </b>
                                    <p> {{data.contact_person_2_phone}}</p>
                                </li>
                            </ul>
                        </div>                    
                    </div>

                    
                </div>
            </div>
        </div>
    </div>


   
</div>

</template>

<script>
import axios from 'axios';
import store from '../store'
export default {
    data() {
        return {
            url: "",
            datas: []
        };
    },
    methods: {        
        async separateParticipants() {
            // ====== To make the list of target participants separately =====
            const participantsDiv = await document.querySelector('.participants-bg');
            const row = await participantsDiv.querySelector('.container').querySelector('.row');
            const participants = row.innerHTML;
            if(participants){
                const list = participants.split(',')
                row.innerHTML='';
                for(const name of list){
                    const div = document.createElement('div');
                    div.classList.add('col-12','col-sm-6','col-md-4','col-lg-3');
                    div.innerHTML=`
                        <div class="content-card text-center mx-auto">
                            <h4 class="m-0 d-flex align-items-center justify-content-center h-100">
                                ${name}
                            </h4>
                        </div>
                    `
                    row.appendChild(div);
                }
            }
        },
        async separateJointlyOrganized(){
            // ===== To make the list of target participants separately =====
            const idDiv = await document.querySelector('#jointly-organized');
            const listDiv = idDiv.innerHTML;
            if (idDiv) {                
                const lists = await listDiv.split(';');
                idDiv.innerHTML='';
                for(const list of lists) {
                    const li = document.createElement('li');
                    li.innerHTML=`${list}`;
                    idDiv.appendChild(li);
                }
            }
        },
        getEvent(){
            axios.post(this.url , {
                    event_id: this.EventID
                   
                })
                .then(response => {
                    console.log(response.data)
                    this.datas = response.data;
                
                })

                //console.log(this.datas)

                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        }
    },
    created() {
        store.commit("EventID",this.$route.params.id)
        this.url = this.WebUrl + "getEvent.php"
        this.getEvent()
        //console.log(this.EventID)    
    },
    mounted(){        
    },
    updated(){
        this.separateParticipants();
        this.separateJointlyOrganized();
    },
    computed: {
        WebUrl() {
            return this.$store.state.web_url;
        },
        EventID(){
            return this.$store.state.event_id;
        }
    }
};
</script>
<style>
.breadcrumb{
    background-color: #fff;
}
.page-banner{
    position: relative;
    width: 100%;
    height: 500px;
    background-color: grey;
    background: url(../assets/event/default.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.page-banner.event-1{
    background: url(../assets/event/event-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.page-banner-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.7);
}
.page-banner-title{
    z-index: 2;
    position: absolute;
    width: 50%;
    top: 50%;
    left: 10%;
    transform: translate(0, -50%);
    color: #fff;
    text-shadow: 2px 2px 10px rgba(0,0,0,.5);
}
.page-banner-title h1{
    font-size: 100px;
    letter-spacing: 15px;
    margin: 0;
}
.page-banner-title p{
    font-size: 20px;
    letter-spacing: 5px;
    margin: 0;
}
.page-banner-title small{
    font-size: 16px;
    letter-spacing: 2px;
    margin: 0;
}

.participants-bg{
    position: relative;
    width: 100%;
    height: auto;
    padding: 25px 0;
    background-color: #969696;
    background: url(../assets/participants-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    top: -30px;
}
.participants-bg .overlay{
    background-color: #fff;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.participants-bg .container{
    position: relative;
    z-index: 2;
    padding: 40px 0;
}
.content-card{
    background-color: rgb(250, 250, 250);
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0,0,0,.25) ;
    max-width: 400px;
    padding: 40px 25px;
    height: 100%;
}

ul li{
    list-style-type: disc !important;
}

.footer-contact{
    position: relative;
    background-color: var(--main);
    padding: 50px 0;
    margin-top: -30px;
}
.footer-contact-box{
    width: fit-content;
    padding: 10px 30px;
    margin-left: auto;
    margin-right: auto;
}
.footer-contact-icon i{
    font-size: 40px;
}
ul.footer-contact-list li{
    list-style-type: disc !important;
}
</style>