// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/event/default.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/event/event-1.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/participants-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".breadcrumb{background-color:#fff}.page-banner{position:relative;width:100%;height:500px;background-color:grey;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;background-position:50%}.page-banner.event-1{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:cover;background-position:50%}.page-banner-overlay{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.7)}.page-banner-title{z-index:2;position:absolute;width:50%;top:50%;left:10%;transform:translateY(-50%);color:#fff;text-shadow:2px 2px 10px rgba(0,0,0,.5)}.page-banner-title h1{font-size:100px;letter-spacing:15px;margin:0}.page-banner-title p{font-size:20px;letter-spacing:5px;margin:0}.page-banner-title small{font-size:16px;letter-spacing:2px;margin:0}.participants-bg{position:relative;width:100%;height:auto;padding:25px 0;background-color:#969696;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:cover;background-repeat:no-repeat;background-position:50%;background-attachment:fixed;top:-30px}.participants-bg .overlay{background-color:#fff;opacity:.3;position:absolute;top:0;left:0;width:100%;height:100%}.participants-bg .container{position:relative;z-index:2;padding:40px 0}.content-card{background-color:#fafafa;border-radius:8px;box-shadow:2px 2px 10px rgba(0,0,0,.25);max-width:400px;padding:40px 25px;height:100%}ul li{list-style-type:disc!important}.footer-contact{position:relative;background-color:var(--main);padding:50px 0;margin-top:-30px}.footer-contact-box{width:-moz-fit-content;width:fit-content;padding:10px 30px;margin-left:auto;margin-right:auto}.footer-contact-icon i{font-size:40px}ul.footer-contact-list li{list-style-type:disc!important}", ""]);
// Exports
module.exports = exports;
