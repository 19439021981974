<template>
    <!-- ================================================== Body Content  -->
    <div class="body-content d-block">
        <div class="dashboard-body-content-bg"></div>
        <!-- ===== Content ===== -->
        <div class="body-container">
            <div class="row mx-auto">
                <div class="dashboard-content-card text-center">
                    <h1><b>Welcome to <br> Malaysian Paediatric Resuscitation Society</b></h1>
                </div>
            </div>
        </div>  <!-- <=== End Content  -->
    </div> 
</template>
<script>
export default {

    name: "Home",
    data() {
        return {
           
        }
    },
    methods: {
        create_event() {
            this.$router.push("/create-event");
        },
        products() {
            this.$router.push("/products");
        },

        userlist() {
            this.$router.push("/user-list");
        },
        instructors() {
            this.$router.push("/instructors");
        },
        my_profile() {
            this.$router.push("/my-profile");
        },
        search_event(){
            this.$router.push("/search-event");
        },

        

    },
    created: function () {

    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },

    },
     mounted(){
         
     }

};
</script>

<style>
#app{
    background-color: #f2f2f2 !important;
    z-index: 0;
}
.side-nav-adjustment{
    z-index: 0;
    box-shadow: 2px 2px 10px rgba(0,0,0,.35);
    background-color: rgba(255,255,255,0);
    position: relative;
    top: 0;
    left: 0;
    min-width: 325px;
    height: 100%;
    transition: all .3s ease;
}
.side-nav{
    z-index: 2;
    /* display: flex; */
    box-shadow: 2px 2px 10px rgba(0,0,0,.35);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 325px;
    width: 325px;
    height: 100%;
}
.side-nav-container{
    padding: 100px 25px 20px 25px;
    width: 100%;
}
.mobile-side-nav{
    transition: all .25s ease;    
}
.mobile-side-nav .mobile-button{
    position: absolute;
    top: 120px;
    left: 0;
    padding: 10px;
    background-color: rgba(0,0,0,.25);
    z-index: 2000;
    box-shadow: 2px 2px 10px rgba(0,0,0,.1);
    opacity: .5;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
    transition: all .25s ease;    
}
.mobile-side-nav .mobile-button.close{
    transform: translate(-60px,0);
}
.mobile-side-nav .mobile-button:hover{
    opacity: 1;
}
.mobile-side-nav .mobile-button i{
    background-color: rgba(255, 255, 255,.8);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0,0,0,.1);;
    font-size: 20px;
}
.mobile-side-nav .mobile-option .side-nav{
    z-index: 2100;
    transition: all .25s ease;
}
.mobile-side-nav .mobile-option .overlay{
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.75);
    transition: all .1s ease;
}
.mobile-side-nav .mobile-option .overlay i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
}
.mobile-option.close .overlay{
    opacity: 0;
    pointer-events: none;
}
.mobile-option.close .side-nav{
    transform: translate(-100% , 0);
    pointer-events: none;
}

.user-info{
    margin-top: 40px;
}

.body-content{
    width: 100%;
    position: relative;
}
.dashboard-body-content-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d4d4d4;
    background: url(../assets/dashboard-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .25;
    z-index: -1000;
}
.body-content .body-container{
    width: 100%;
    padding: 50px 12em;
}
@media (max-width: 1200px) {
    .body-content .body-container{
        padding: 50px 50px;
    }
}

.dashboard-content-card{
    position: relative;
    overflow: hidden;
    background-color: rgba(255, 255, 255,.8);
    border-radius: 4px;
    box-shadow: 2px 2px 10px rgba(0,0,0,.15);
    padding: 40px 50px;
    height: 100%;
}
.dashboard-content-card .top-left-corner{
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    opacity: .75;
    transition: opacity .3s ease;
}
.dashboard-content-card .top-left-corner:hover{
    opacity: 1;
}
.v-expansion-panel-content .v-expansion-panel-content__wrap{
    padding-bottom: 0 !important;
}
.sub-options{
    transition: background-color .3s ease;
}
.sub-options:hover{
    background-color: #d4d4d4;
}
button.btn-primary:focus{
    background-color: #094092 !important;
}
.mobile-logout{
    margin: 75px 0 50px 0;
}
.mobile-logout button {
    padding: 20px 40px;
}

.hide-nav-menu{
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 1000;
    background: rgba(255,255,255,1);
    left: 325px;  
    top: 100px;  
    border-radius: 0 4px 4px 0;
    box-shadow: 5px 4px 10px rgba(0,0,0,.15);
    cursor: pointer !important;
    font-size: 22px;
    transition: all .2s ease;
    transform-origin: left;
    transform: translate(0, 25px)
                scaleX(0);
    opacity: 0;
    pointer-events: none;
}
.hide-nav-menu.show{
    transform: translate(0, 25px)
                scaleX(1);
    opacity: .3;
    pointer-events: auto;
}
.hide-nav-menu.show.close{
    transform: translate(-325px, 25px)
                scaleX(1);
}
.hide-nav-menu.show:hover{
    opacity: .75;
}
.hide-nav-menu i{
    transition: transform .3s ease;
}
.hide-nav-menu.close i{
    transform: rotateZ(180deg);
}
.side-nav-adjustment.close{
    position: fixed;
    transform: translate(-100%,0);
}
</style>
