<template>
<div class="body-content d-block">
    <div class="dashboard-body-content-bg"></div>
    <!-- ===== Content ===== -->
    <div class="body-container">
        <div class="row mx-auto">
            <div class="col-12">
                <div class="dashboard-content-card text-center">
                    <h3><b>User List for Admin in MPRS</b></h3>
                    <hr>
                    <div class="dashboard-table">
                        <div class="table-responsive" v-if="datas != null">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th class="text-left">ID</th>
                                        <th class="text-left">Email</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Password</th>
                                        <th>Action </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td><input type='email' v-model='email'></td>
                                        <td><input type='text' v-model='name'></td>
                                        <td><input type='text' v-model='password'></td>
                                        <td>
                                            <v-btn depressed color="green" dark @click="addRecord">Add User</v-btn>
                                        </td>

                                    </tr>
                                    <tr v-for="(data, index) in datas" :key="index">
                                        <td>{{ data.ID }}</td>
                                        <td><input type='email' v-model='data.email'></td>
                                        <td><input type='text' v-model='data.name'></td>
                                        <td><input type='text' v-model='data.password'></td>
                            
                                        <td>
                                            <v-btn depressed color="blue" dark @click="editUser(index,data.ID)">Update </v-btn>
                                            <v-btn depressed color="red" dark @click="deleteUser(data.ID)">Delete </v-btn>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>
                    
                </div>  <!-- <=== END dashboard-content-card -->
            </div>
        </div>
    </div>  <!-- <=== End Content  -->
</div>
</template>

<script>
import axios from 'axios';

export default {
    name: "UserList",
    components: {

    },
    data() {

        return {
            datas: [],
            show: false,
            role: "3",
            email: "",
            name: "",
            password: "",
            url:""
        };
    },
    methods: {
        addRecord: function () {

            if (this.email != '' && this.password != '') {
                axios.post(this.url, {
                        request: 3,
                        name: this.name,
                        email: this.email,
                        password: this.password,
                        created_by: this.UserEmail

                    })
                    .then(response => {
                        this.name = '';
                        this.email = '';
                        this.password = '';

                        this.show_user();
                        console.log(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                alert('Fill all fields.');
            }

        },
        editUser(index, id) {
            // update meetings in firestores
            var email = this.datas[index].email;
            var name = this.datas[index].name;
            var password = this.datas[index].password;
    

            axios.post(this.url, {
                    request: 2,
                    id: id,
                    name: name,
                    email: email,
                    password: password
              
                })
                .then(response => {
                    alert(response.data);
                    this.show_user();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        show_user() {
            axios.post(this.url, {
                    request: 1,
                })
                .then(response => {

                    this.datas = response.data;

                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        deleteUser(id) {

            axios.post(this.url, {
                    request: 4,
                    id: id

                })
                .then(response => {
                    alert(response.data);
                    this.show_user();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    created() {
        this.url = this.WebUrl + "admin_userlist.php"
        this.show_user()

    },

    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },
    }

};
</script>

<style>
.v-select {
    display: grid;
}
td button{
    margin: 2px 1px;
}
</style>
