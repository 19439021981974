<template>
<div class="body-content d-block">
    <div class="dashboard-body-content-bg"></div>
    <!-- ===== Content ===== -->
    <div class="body-container">
        <div class="row mx-auto">
            <div class="col-12">
                <div class="dashboard-content-card text-center">
                    <h2 class="center-align black-text">
                        <span>Create MPRS Event</span>
                    </h2>
                    <hr>
                    <form class="create-event" @submit.prevent="createEvent">
                        <div class="row mx-0 mt-5">
                            <div class="col-12">
                                <div class="field event_type">
                                    <select v-model="event_type">
                                        <option value="">- - Select Event Type - -</option>
                                        <option value="APLS">APLS</option>
                                        <option value="PLS">PLS</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field event_name">
                                    <input type="text" name="event_name" v-model="event_name" placeholder="Event Name">
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="start_date mx-auto" style="width:95%">
                                    <label for="start_date">Start Date:</label>
                                    <input type="date" name="start_date" v-model="start_date">
                                </div>                                
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="end_date mx-auto" style="width:95%">
                                    <label for="end_date">End Date:</label>
                                    <input type="date" name="end_date" v-model="end_date">
                                </div>                                
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="start_time mx-auto" style="width:95%">
                                    <label for="start_time">Start Time:</label>
                                    <input type="time" name="start_time" v-model="start_time">
                                </div>                                
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="end_time mx-auto" style="width:95%">
                                    <label for="end_time">End Time:</label>
                                    <input type="time" name="end_time" v-model="end_time">
                                </div>                                
                            </div>
                            <div class="col-12">
                                <div class="field venue">
                                    <input type="text" name="venue" v-model="venue" placeholder="Venue">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field target_participants">
                                    <input type="text" name="target_participants" v-model="target_participants" placeholder="Target Participants">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field whatsapp_target">
                                    <input type="text" name="whatsapp_target" v-model="whatsapp_target" placeholder="Whatsapp Target">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field whatsapp_number">
                                    <input type="text" name="whatsapp_number" v-model="whatsapp_number" placeholder="Whatsapp Number">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field contact_person_1">
                                    <input type="text" name="contact_person_1" v-model="contact_person_1" placeholder="Contact Person 1">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field contact_person_1_phone">
                                    <input type="text" name="contact_person_1_phone" v-model="contact_person_1_phone" placeholder="Contact Person 1 Phone Number">
                                </div>
                            </div>                   
                            <div class="col-12">
                                <div class="field contact_person_2">
                                    <input type="text" name="contact_person_2" v-model="contact_person_2" placeholder="Contact Person 2">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field contact_person_2_phone">
                                    <input type="text" name="contact_person_2_phone" v-model="contact_person_2_phone" placeholder="Contact Person 2 Phone Number">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="field jointly_organized">
                                    <label for="jointly_organized">( please use semicolon ";" to seperate each organizer )</label>
                                    <input type="text" name="jointly_organized" v-model="jointly_organized" placeholder='Jointly Organizer (please use semicolon ";" to seperate each organizer)'>
                                </div>
                            </div>
                            <div class="col-12 auto-complete mt-5">
                                <v-autocomplete
                                    v-model="invited_instructors"
                                    :items="instructors"
                                    item-text="name"
                                    item-value="ID"
                                    dense
                                    label="Invited Instructors"
                                    multiple
                                    style="border:0px !important"
                                ></v-autocomplete>
                            </div>
                            <hr class="my-5">                     
                            <div class="field center-align">
                                <p v-if="feedback" class="red-text">{{ feedback }}</p>
                                <button class="btn btn-primary px-5 py-5">
                                    <span class="d-flex align-items-center h-100">Create new event</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="top-left-corner">
                        <router-link :to="{ name: 'Home' }">
                            <v-btn class="d-flex align-items-center h-auto py-3 border" depressed >
                                <i class="bi bi-arrow-left-circle"></i><span class="d-none d-sm-block ms-2">Back</span>
                            </v-btn>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>  <!-- <=== End Content  -->
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: "CreateEvent",
    data() {
        return {
            event_name: "",
            start_date: "",
            end_date: "",
            venue: "",
            start_time: "",
            end_time: "",
            target_participants: "",
            whatsapp_number: "",
            whatsapp_target: "",
            jointly_organized: "",
            feedback: null,
            contact_person_1: "",
            contact_person_2: "",
            contact_person_1_phone: "",
            contact_person_2_phone: "",
            url: "",
            instructors: [],
            invited_instructors:"",
            event_type:""
            
        };
    },
    methods: {
        createEvent() {
            
            this.url = this.WebUrl + "createEvent.php"
            axios.post(this.url, {
                    event_type: this.event_type,
                    event_name: this.event_name,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    venue: this.venue,
                    start_time: this.start_time,
                    end_time: this.end_time,
                    target_participants: this.target_participants,
                    whatsapp_number: this.whatsapp_number,
                    whatsapp_target: this.whatsapp_target,
                    jointly_organized: this.jointly_organized,
                    contact_person_1: this.contact_person_1,
                    contact_person_1_phone: this.contact_person_1_phone,
                    contact_person_2: this.contact_person_2,
                    contact_person_2_phone: this.contact_person_2_phone,
                    user: this.UserEmail,
                    invited_instructors: JSON.stringify(this.invited_instructors)
                })
                .then(response => {
                    console.log(response.data);
                    this.$router.push("/home");
                })
                .catch(function (error) {
                    console.log(error);
                });
                
               console.log(this.invited_instructors)
        },
        get_instructors(){
            this.url = this.WebUrl + "instructors.php"
            console.log(this.url)
            axios.post(this.url, {
                    request:1
                })
                .then(response => {
                    this.instructors = response.data;
                    console.log(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

    },
    created() {

        this.get_instructors()
    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },
    }

};
</script>

<style>
select {
    display: block !important
}
form.create-event .col-6 , form.create-event .col-12{
    padding: 0;
}
form.create-event .field{
    margin: 8px 0;
}
form.create-event select , form.create-event .col-12 input , form.create-event .field input{
    border-radius: 4px;
    border: solid 1px rgba(0,0,0,.1);
    padding: 2px 15px;
    box-sizing: border-box;
    width: 100%;
}
form.create-event .col-12.auto-complete input{
    border: solid 0px rgba(0,0,0,0);    
    padding: 2px 15px;
    box-sizing: border-box;
    width: 100%;
}
form.create-event .col-12.auto-complete .v-input__slot{
    border: solid 1px rgba(0,0,0,.1);    
    padding: 15px 15px 0 15px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
}
form.create-event .col-12.auto-complete .v-select__selections{
    padding: 2px 15px;
    box-sizing: border-box;
    width: 100%;
}
form.create-event input::placeholder{
    color: rgba(0,0,0,.45);
}
</style>
