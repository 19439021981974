<template>
    <div class="body-content d-block">
        <div class="dashboard-body-content-bg"></div>
            <!-- ===== Content ===== -->
        <div class="body-container">
            <div class="row mx-auto">
                <div class="col-12">

                    <div class="dashboard-content-card text-center">
                        <h3 class="center-align"><b>Search Event</b></h3>
                        <hr>
                        <div class="mt-5 row search-event-input">
                            <div class="col-12 col-sm-6">
                                <div class="field start_date">
                                    <label for="date">Start Date: </label>                                        
                                    <input type="date" id="start_dte" name="start_date" v-model="start_date">
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="field end_date">
                                    <label for="date">End Date: </label>
                                    <input type="date" id="end_date" name="end_date" v-model="end_date">
                                </div>
                            </div>
                            <div class="col-12">
                                <div style="text-align:center">
                                    <p v-if="feedback" class="red-text">{{ feedback }}</p>
                                    <v-btn class="blue" dark @click="searchEvent">
                                        Search
                                    </v-btn>
                                </div>
                            </div>                            
                        </div>
                        <div class="col-12 top-left-corner">
                            <router-link :to="{ name: 'Home' }">
                                <v-btn class="d-flex align-items-center h-auto py-3 border" depressed ><i class="bi bi-arrow-left-circle"></i><span class="d-none d-sm-block ms-2">Back</span></v-btn>
                            </router-link>
                        </div>
                    </div>  <!-- <=== ENDdashboard-content-card -->        

                </div>
                <div class="col-12" v-if="datas != null && loaded">
                    <div class="dashboard-content-card text-center">
                        <div class="dashboard-table">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Event Name</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Venue</th>
                                        <th>Target Participants</th>
                                        <th>Whatsapp Target</th>
                                        <th>Whatsapp Number</th>
                                        <th>Jointly Organized with</th>
                                        <th>Contact Person 1</th>
                                        <th>Contact Person 1 Phone</th>
                                        <th>Contact Person 2</th>
                                        <th>Contact Person 2 Phone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, index) in datas" :key="index">
                                        <td>{{ data.start_date }}</td>
                                        <td>{{ data.end_date }}</td>
                                        <td>{{ data.name }}</td>
                                        <td>{{ data.start_time }}</td>
                                        <td>{{ data.end_time }}</td>
                                        <td>{{ data.venue }}</td>
                                        <td>{{ data.target_participants }}</td>
                                        <td>{{ data.whatsapp_target }}</td>
                                        <td>{{ data.whatsapp_number }}</td>
                                        <td>{{ data.jointly_organized }}</td>
                                        <td>{{ data.contact_person_1 }}</td>
                                        <td>{{ data.contact_person_1_phone }}</td>
                                        <td>{{ data.contact_person_2 }}</td>
                                        <td>{{ data.contact_person_2_phone }}</td>
                                        <td>
                                            <v-btn depressed color="blue" dark @click="editEvent(data.ID)" style="margin-right:10px">Update </v-btn>
                                            <v-btn depressed color="red" dark @click="deleteEvent(data.ID)" style="margin-right:10px">Delete </v-btn>
                                           
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                        <div v-if="!loaded" style="text-align:center;">
                            <br>
                            <p>Sorry , there is no event.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>  <!-- <=== End Content  -->

    </div>
</template>

<script>
import axios from 'axios';
import store from "../store";
import Swal from 'sweetalert2'

export default {
    name: "SearchEvent",
    data() {

        return {
            datas: null,
            start_date: "",
            end_date: "",
            feedback: null,
            loaded: true
        };
    },
    methods: {

        editEvent(id) {
            store.commit("EventID", id);
            this.$router.push("/update-event");
        },
        searchEvent() {
            axios.post(this.url , {
                    request: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                })
                .then(response => {
                    console.log(response.data)
                    this.datas = response.data;
                    if (this.datas.length == 0) {
                        this.loaded = false
                    } else {
                        this.loaded = true
                    }

                })

                //console.log(this.datas)

                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        deleteEvent(id) {

    
            Swal.fire({
                text: "Are you sure you want to delete this event?",
                title: "Delete Event",
                showCancelButton: true,
                confirmButtonText: 'Yes, Delete it!',
                cancelButtonText: 'No, Keep it!',
                confirmButtonColor:'green',
                cancelButtonColor:'red',
                showLoaderOnConfirm: false
            }).then((result) => {
                if (result.value) {

                    axios.post(this.url , {
                            request: 4,
                            id: id

                        })
                        .then(response => {

                            alert(response.data);
                            this.searchEvent();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }

            })

        },


    },
    created() {
        this.url = this.WebUrl + "SearchEvent.php"
        this.report_type = 'all'
       
    },

    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },
        EventID() {
            return this.$store.state.event_id;
        },
    }

};
</script>

<style>
.v-select {
    display: grid;
}

select.swal2-select {
    display: none !important;
}
.search-event-input input {
    border-radius: 4px !important;
    border: solid 1px rgba(0,0,0,.1) !important;
    padding: 2px 15px !important;
    box-sizing: border-box !important;
    width: 100% !important;
}
body > div.swal2-container.swal2-center.swal2-backdrop-show > div > div.swal2-actions > button.swal2-confirm.swal2-styled.swal2-default-outline:focus{
    background-color: green !important;
}
</style>
