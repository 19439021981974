<template>
<div class="body-content d-block">
    <div class="dashboard-body-content-bg"></div>
    <!-- ===== Content ===== -->
    <div class="body-container">
        <div class="row mx-auto">
            <div class="col-12">
                <div class="dashboard-content-card">
                    <div class="text-center mb-5">
                        <loading :active.sync="isLoading" 
                        :is-full-page="fullPage"></loading>
                        <h1><b>Profile</b></h1>
                    </div>
                    <hr class="mb-5">
                    <div class="profile-form mx-auto">
                        <form>
                            <div v-for="(data, index) in datas" :key="index">
                                 <div class="field name">
                                    <label for="name">Name:</label>                    
                                    {{ data.name }}
                                </div>                            
                                <div class="field email">
                                    <label for="email">Email:</label>                            
                                    {{ data.email }}
                                </div>
                                <div class="spacing"></div>
                                <div class="field password">
                                    <v-row>
                                        <v-col>
                                            <label for="password">Password:</label>                                        
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <v-col class="input w-100">
                                            <input :type="passwordFieldType" id="password" class="w-auto me-2 mt-2" v-model="data.password">
                                            <v-btn class="btn d-inline-flex mx-auto" small dark @click="switchVisibility()">
                                                <v-icon>remove_red_eye</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </div>
                                </div>                                                          
                                <div class="field center-align">
                                    <p v-if="feedback" class="red-text">{{ feedback }}</p>
                                    <loading :show="show" :label="label" :overlay="overlay">

                                    </loading>
                                    <button v-on:click.prevent="update(index)" class="btn btn-warning btn-extended lighten-4 black-text py-4 px-5 d-flex align-items-center mx-auto">
                                        <span>Update Password</span>
                                       
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>  <!-- <=== End Content  -->
</div>
</template>


<script>
import axios from 'axios'
import loading from 'vue-full-loading'

export default {
    name: "Profile",
    components: {
        loading,
       
    },
    data: function () {
        return {
            passwordFieldType: 'password',
            feedback: null,
            datas: "",
            show: false,
            label: '',
            overlay: true,
            isLoading: false,
            fullPage: true,
            url: ""
        };
    },
    methods: {
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        update: function (index) {
            this.feedback = ""
            if (this.password == "") {
                this.feedback = "You must enter a password";
                return;
            }
            this.feedback = null;

            this.url = this.WebUrl +'update_admin_profile.php'
            var password = this.datas[index].password
        
		
            axios.post(this.url , {
                email: this.UserEmail,
                password: password

            }).then(response => {
                
                alert(response.data)
                this.show_profile()
            }).catch(function (error) {
                console.log(error);
            });
        },
        show_profile() {
            this.isLoading = true
          
            this.url = this.WebUrl + "show_admin_profile.php"
            axios.post(this.url, {
                email: this.UserEmail,

            }).then(response => {
                this.datas = response.data
                this.isLoading = false
            }).catch(function (error) {
                console.log(error);
            });
        }

    },
    created() {
      
      this.show_profile()
     
    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },

    }

}
</script>

<style>
select {
    display: inline-block;
    border-bottom: 1px solid #9e9e9e;
}

label {
    font-weight: bold;
    color: black;
}

h2 {
    font-size: calc(20px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

select {
    display: block !important;
}
.profile-form{
    max-width: 1000px;
    width: auto;
}
.profile-form .spacing {
    margin: 30px 0;
}
.profile-form .input {
    position: relative;
    margin: 10px 0; 
}
.profile-form .input input {       
    padding: 0px 10px !important;
    border-radius: 4px !important;
    border: 1px solid rgba(0,0,0,.25) !important;
    width: 90% !important;
}
.profile-form .input .btn{
    position: absolute;
    top: 50%;
    right: 0;
    height: 70%;
    transform: translate(0, -50%);
}
</style>
