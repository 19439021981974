import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store' 

import Instructors from '@/views/Instructors'
import Home from '@/views/Home'
import Login from '@/views/Login'
import UserList from '@/views/UserList'
import MainPage from '@/views/MainPage'
import MyProfile from '@/views/MyProfile'
import CreateEvent from '@/views/CreateEvent'
import SearchEvent from '@/views/SearchEvent'
import UpdateEvent from '@/views/UpdateEvent'
import InviteInstructors from '@/views/InviteInstructors'
import Events from '@/views/Events'
import ForgetPassword from '@/views/ForgetPassword'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/invite-instructors',
    name: 'InviteInstructors',
    component: InviteInstructors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create-event',
    name: 'CreateEvent',
    component: CreateEvent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/update-event',
    name: 'UpdateEvent',
    component: UpdateEvent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/search-event',
    name: 'SearchEvent',
    component: SearchEvent,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
   
  },
  {
    path: '/events/:id',
    name: 'Events',
    component: Events,
   
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
    meta: {
      requiresAuth: true
    }
  }, 
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/password-reset',
    name: 'ForgetPassword',
    component: ForgetPassword,
  },
  {
    path: '/instructors',
    name: 'Instructors',
    component: Instructors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-list',
    name: 'UserList',
    component: UserList,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.isLoggedIn) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
