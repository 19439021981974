<template>
<div class="login z-depth-1">
    <div class="login-bg"></div>
    <div class="overlay"></div>
    <div class="login-box shadow rounded text-center">
        <h3><b>Login</b></h3>
        <hr>
        <div class="field email mx-4">
            <input id="email" class="input-box" placeholder="Email Address" type="email"  v-model="email">
        </div>
        <div class="field password mx-4">
            <input id="password" class="input-box" placeholder="Password" type="password"  v-model="password" v-on:keyup.enter="login(email,password)">
        </div>
        <div class="field center-align">
            <p v-if="feedback" class="red-text">{{ feedback }}</p>
            <button class="btn btn-light" @click="login(email,password)" type="submit">
                Login
            </button>
        </div>
        <hr>
        <div class="text-center mt-4">
            <a href="/password-reset">Forget Password?</a>
        </div>
    </div>
</div>
</template>


<script>
import store from "../store";
import axios from 'axios';

export default {
    data() {
        return {
            email: null,
            password: null,
            feedback: null,
            datas: [],
            url: "",

        };
    },
    methods: {

        login: function (email, password) {
            this.feedback = ""
            if (email == null) {

                this.feedback = "Please enter your email"

                return;
            }
            this.url = this.WebUrl + "admin_login.php"
            axios.post(this.url, {
                    email: email,
                    password: password
                })
                .then(response => {

                    if (response.data.length == 0) {
                        this.feedback = "This email is not exist! Please contact admin"
                        return;
                    }
                    if (response.data[0].login_status == 'success') {
                        store.commit("IsLoggedIn", true);
                        store.commit("Email", this.email);
                        store.commit("Name",response.data[0].name)
                        this.$router.push("/home");
                        
                    }
                    else{
                        alert(response.data)
                    }
                })
                .catch(function (error) {
    
                    console.log(error);
                });
        },
    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },
        Name(){
            return this.$store.state.name;
        }



    }
};
</script>

<style>
.login-bg{
    box-sizing: border-box;
    position: absolute;
    background-color: var(--main);
    background: url(../assets/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.login .overlay{
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
}
.login-box{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 650px;
    min-width: 500px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,.65);
    padding: 30px 50px;
    z-index: 5;
}
.input-box{
    border: 1px solid rgba(0,0,0,.5) !important;
    border-radius: 4px !important;
    padding: 5px 10px !important;
    width: 90% !important;
    margin: 0 !important;
}
.input-box::placeholder{
    color: #000;
    opacity: .5;
}
.input-box:focus{
    background: rgb(240, 240, 240) !important;
    box-shadow: 1px 1px 3px 1px rgb(153, 153, 153) inset !important;
}
.field {
    margin: 15px 0;
}
a {
    text-decoration: none;
}
</style>
