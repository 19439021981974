<template>
<div>
    <div class="container z-depth-1" style="background-color:white">
        <h2 class="center-align black-text">
            <span>Invite Instructors</span>
        </h2>

        <div class="field organizer_state">
            <label for="organizer_state">Organizer State :</label>
            <select id="category" v-model="organizer_state">
                <option disabled value="">Please select one</option>
                <option v-for="(place, index) in places" :key="index" v-bind:value="place.place_state">
                    {{ place.place_state }}

                </option>
            </select>
        </div>
        <div class="field center-align">
            <p v-if="feedback" class="red-text">{{ feedback }}</p>
            <button class="btn blue" @click="search">
                <span>Search</span>
            </button>
            <br><br>
            <router-link :to="{ name: 'Home' }">
                <button class="btn pink">
                    <span>Back</span>
                </button>
            </router-link>
        </div>

    </div>
    <div v-if="datas != null">
        <sorted-table :values="datas" ascIcon="<span> ▲</span>" descIcon="<span> ▼</span>">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <sort-link name="name">
                            Name
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="gic_date">
                            Date of GIC
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="status">
                            Status
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="country">
                            Country
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="place_state">
                            State
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="workplace">
                            Workplace
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="phone">
                            Phone Number
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="comittee_status">
                            Still committed to APLS/ PLS
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="last_course_date">
                            Last Course
                        </sort-link>
                    </th>
                    <th>
                        <sort-link name="email">
                            Email Address
                        </sort-link>
                    </th>

                </tr>
            </thead>
            <template #body="sort">
                <tbody>

                    <tr v-for="(data, index) in sort.values" :key="index">
                        <td>
                            <v-checkbox v-model="data.email_status"></v-checkbox>
                        </td>
                        <td>{{ data.name }}</td>
                        <td>{{ data.gic_date }}</td>
                        <td>{{ data.status }} </td>
                        <td>{{ data.country }}</td>
                        <td>{{ data.place_state }}</td>
                        <td>{{ data.workplace }}</td>
                        <td>{{ data.phone }}</td>
                        <td>{{data.comittee_status }}</td>
                        <td>{{ data.last_course_date }}</td>
                        <td>{{ data.email }}</td>

                    </tr>

                </tbody>
            </template>
        </sorted-table>
    </div>
    <div class="field center-align">
        <button class="btn green" @click="send_email">
            <span>Send Email</span>
        </button>
        <br><br>

    </div>
</div>
</template>

<script>
import axios from 'axios'
import { SortedTable, SortLink } from "vue-sorted-table";
export default {
    name: "InviteInstructors",
    components: {
        SortedTable,
        SortLink,

    },
    data() {
        return {
            datas: [],
            feedback: null,
            places: [],
            organizer_state: "",
            url:""
        };
    },
    methods: {
        send_email() {
            var email_arr = new Array()

            this.datas.forEach(function (data) {
                if (data.email_status) {
                    email_arr.push(data.email)
                }

            })
            for (var i = 0; i < email_arr.length; i++) {
                this.url = this.WebUrl + "invite_instructors.php"
                axios.post(this.url, {
                        email: email_arr[i],
                        event: this.eventID
                    })
                    .then(response => {

                        console.log(response.data)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            //alert("Send email complete!")
        },
        search() {
            this.url = this.WebUrl + "getInstructorsInvitation.php"
            axios.post(this.url, {
                    organizer_state: this.organizer_state
                })
                .then(response => {
                    this.datas = response.data
                    this.datas.forEach(function (data) {
                        data.email_status = false

                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        get_place() {
            this.url = this.WebUrl + "getPlace.php"
            axios.post(this.url)
                .then(response => {
                    this.places = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    },
    created() {
        this.get_place()
    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        eventID() {
            return this.$store.state.eventID;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },

    }

};
</script>

<style>
select {
    display: block !important
}
</style>
