<template>
<div class="login z-depth-1">
    <div class="login-bg"></div>
    <div class="overlay"></div>
    <div class="login-box shadow rounded text-center">
        <h3><b>Reset Password</b></h3>
        <hr>
        <div class="field email mx-4">
            <input id="email" class="input-box" placeholder="Email Address" type="email"  v-model="email">
        </div>
        <div class="field center-align">
            <p v-if="feedback" class="red-text">{{ feedback }}</p>
            <button class="btn btn-light" @click="reset(email)" type="submit">
                Reset Password
            </button>
        </div>
        <hr>
        
    </div>
</div>
</template>


<script>

import axios from 'axios';

export default {
    data() {
        return {
            email:"",
            
            feedback: null,
            datas: [],
            url: "",

        };
    },
    methods: {

        reset: function (email) {
            this.feedback = ""
            if (email == "") {
                this.feedback = "Please enter your email"
                return;
            }
            this.url = this.WebUrl + "reset_password.php"
            axios.post(this.url, {
                email: email,
            })
            .then(response => {
                console.log(response.data);
                if(response.data == "Password has been reset and sent to your email.") {
                    alert(response.data)
                    this.$router.push("/login");
                }
                else {
                    this.feedback = response.data
                    this.email = ""
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },
        Name(){
            return this.$store.state.name;
        }



    }
};
</script>

<style>
.login-bg{
    box-sizing: border-box;
    position: absolute;
    background-color: var(--main);
    background: url(../assets/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.login .overlay{
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
}
.login-box{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 650px;
    min-width: 500px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,.65);
    padding: 30px 50px;
    z-index: 5;
}
.input-box{
    border: 1px solid rgba(0,0,0,.5) !important;
    border-radius: 4px !important;
    padding: 5px 10px !important;
    width: 90% !important;
    margin: 0 !important;
}
.input-box::placeholder{
    color: #000;
    opacity: .5;
}
.input-box:focus{
    background: rgb(240, 240, 240) !important;
    box-shadow: 1px 1px 3px 1px rgb(153, 153, 153) inset !important;
}
.field {
    margin: 15px 0;
}
a {
    text-decoration: none;
}
</style>
