<template>
  <v-app>
    <div id="app" style="height: 100%;">
      <Navbar />
      <div style="margin-top: 100px" v-if="RouteName">
        <router-view />
      </div>
      <div v-else> 
        <div class="h-100 w-100 d-inline-flex">
          <!-- ================================================== side NAV  -->
          <div class="d-none d-md-block side-nav-adjustment">     <!-- <=== available "close" -->
              <div id="side-nav" class="side-nav">                
                  <div class="side-nav-container">
                      <div class="user-info">
                          <h4 class="m-0"><b>{{ Name }}</b></h4>
                        
                          <div>
                              <button class="btn btn-primary my-4 d-flex align-items-center justify-content-center" @click="my_profile">
                                  <small>My Profile</small>
                              </button>
                          </div>
                      </div>
                      <hr>                    
                  </div>  <!-- <=== side nav container END  -->
                  <div>
                      <v-expansion-panels focusable>
                          <v-expansion-panel
                              v-for="option in options"
                              :key="option.title"
                          >                            
                              <v-expansion-panel-header>
                                  <v-icon>{{option.icon}}</v-icon>
                                  <b>{{option.title}}</b>                           
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="v-expansion-panel-content pt-4">
                                  <ul class="sub-options"
                                      v-for="event in eventManagement"
                                      :key="event.item"
                                  >
                                      <a :href="event.link" class="text-dark">
                                          <li class="py-3">
                                              {{event.item}}
                                          </li>
                                      </a>
                                  </ul>
                              </v-expansion-panel-content>
                          </v-expansion-panel>
                      </v-expansion-panels>
                  </div>  <!-- <=== end of menus -->          
              </div>         
          </div>
          
          <!-- ===== Hide Side Nav Menu ===== -->
          <span class="d-none hide-nav-container">
              <div class="hide-nav-menu d-none d-md-block show"> <!-- <=== available "show" "close" -->
                  <div class="d-flex align-items-center justify-content-center h-100">
                      <i class="bi bi-caret-left-fill"></i>
                  </div>
              </div>
          </span>
          
          <!-- ===== MOBILEE SIDE NAV ===== -->
          <div id="mobile-side-nav" class="mobile-side-nav d-block d-md-none">
              <div class="mobile-button"> <!-- <== "close" Class Available -->
                  <i class="bi bi-list d-flex align-items-center justify-content-center"></i>
              </div>
              <div class="mobile-option close"> <!-- <== Close Class Available -->                
                  <div class="overlay"><i class="bi bi-x-lg"></i></div>
                  <div class="side-nav">                    
                      <div class="side-nav-container" style="margin-top:-80px"></div>                    
                      <div>
                          <v-expansion-panels focusable>
                              <v-expansion-panel
                                  v-for="option in options"
                                  :key="option.title"
                              >                            
                                  <v-expansion-panel-header>
                                      <v-icon>{{option.icon}}</v-icon>
                                      <b>{{option.title}}</b>                           
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content class="v-expansion-panel-content pt-4">
                                      <ul class="sub-options"
                                          v-for="event in eventManagement"
                                          :key="event.item"
                                      >
                                          <a :href="event.link" class="text-dark">
                                              <li class="py-3">
                                                  {{event.item}}
                                              </li>
                                          </a>
                                      </ul>
                                  </v-expansion-panel-content>
                              </v-expansion-panel>
                          </v-expansion-panels>
                      </div>  <!-- <=== end of menus -->  
                      <div class="mobile-logout">
                          <button class="btn-warning btn d-flex align-items-center mx-auto">
                              <small class="d-flex align-items-center"><i class="pe-2 bi bi-box-arrow-right"></i> Logout</small>
                          </button>
                      </div>
                  </div>
                  
              </div>
          </div>

          <!-- ================================================== Body Content  -->
          <router-view style="margin-top: 100px" />         
        </div>

      </div>   
    </div>
    <v-footer class="justify-center footer" style="text-align:center">
      <div>          
          <span>&copy; Copyright {{ (new Date()).getFullYear() }} MPRS All Rights Reserved. </span>       
      </div>      
    </v-footer>
  </v-app>
</template>

<script>
import Navbar from '@/views/Navbar'
// import DashboardLayout from '@/views/DashboardLayout'
export default {
  name: 'App',
  data() {
      return {
          options: [
              { title: 'Event Management', icon: 'mdi-calendar-text' },
          ],
          eventManagement: [
              { item: 'User List', link:'/user-list' },
              { item: 'Instructors', link:'instructors' },
              { item: 'Create Event', link:'create-event' },
              { item: 'Search Event', link:'search-event' },
          ],
      }
  },
  methods: {
      create_event() {
          this.$router.push("/create-event");
      },
      products() {
          this.$router.push("/products");
      },

      userlist() {
          this.$router.push("/user-list");
      },
      instructors() {
          this.$router.push("/instructors");
      },
      my_profile() {
          this.$router.push("/my-profile");
      },
      search_event(){
          this.$router.push("/search-event");
      },

      // =============== SIDE NAV ===============
      async setupMobileNav () {
        if (!document.querySelector('#mobile-side-nav')) {
            return;
        } else {
            const originalNav = await document.querySelector('#side-nav').querySelector('.side-nav-container');            
          const mobileDiv = await document.querySelector('#mobile-side-nav');      
          const mobileOption = await mobileDiv.querySelector('.mobile-option');                        
          const mobileBtn = await mobileDiv.querySelector('.mobile-button');
          const mobileProfileNav = await mobileDiv.querySelector('.side-nav').querySelector('.side-nav-container');

          // ===== Side Nav Content =====
          
          // ===== Insert the side nav option ====
          const profile = document.createElement('div');
          profile.innerHTML = originalNav.innerHTML;
          mobileProfileNav.append(profile);

          // ===== Setup Show Button =====
          mobileBtn.addEventListener('click',()=>{
              if(!mobileBtn.classList.contains('close')) {
                  mobileBtn.classList.add('close');
                  setTimeout(async () => {
                      if(!mobileBtn.classList.contains('hidden')) {
                          mobileBtn.classList.add('hidden');
                      }
                  }, 250)
              }
              mobileOption.classList.remove('hidden');
              setTimeout(() => {
                  mobileOption.classList.remove('close');
              }, 1);
          });

          // ===== Setup Close Button =====
          mobileOption.addEventListener('click',(e)=>{
              const targetNav = document.querySelector('#mobile-side-nav').querySelector('.side-nav');
              if (!targetNav.contains(e.target)) {
                  if (!mobileOption.classList.contains('close')){
                      mobileOption.classList.add('close');
                      setTimeout(() => {
                          if (!mobileOption.classList.contains('hidden')){
                              mobileOption.classList.add('hidden');
                          }                            
                      }, 250);
                  }
              }
              mobileBtn.classList.remove('hidden');
              setTimeout(() => {
                  mobileBtn.classList.remove('close');
              }, 100);
          });
        }
      },
      async setupHideSideNav() {            
          const sideNav = await document.querySelector('.side-nav-adjustment');
          const hideDiv = await document.querySelector('.hide-nav-container');
          const hideBtn = await document.querySelector('.hide-nav-menu');

          // ===== Active hide sidenav button =====
          if (hideDiv) {
              await hideDiv.classList.remove('d-none');
              if (!hideBtn.classList.contains('show')) {
                  await hideBtn.classList.add('show');
              }
          }
          
          // ===== setup click action to the button =====
          if (hideBtn) {
              hideBtn.addEventListener('click',()=>{
                  hideBtn.classList.toggle('close');
                  sideNav.classList.toggle('close');
              });
          }
          
      },

  },
  mounted(){
      this.setupMobileNav();
      this.setupHideSideNav();
  },
  updated(){
    this.setupMobileNav();
    this.setupHideSideNav();
  },
  components: {
    Navbar,
    // DashboardLayout
  },
  created(){
    //console.log(this.RouteName)
  },
  computed: {
    Name(){
        return this.$store.state.name;
    },
    UserEmail() {
      return this.$store.state.Email;
    },
    Language() {
      return this.$store.state.language;
    },
    IsLogin() {
      return this.$store.state.is_login;
    },
    RouteName(){
      var status = true
      var route = this.$route.name
      if(route == 'Events'){
        status = true
      }
      else if (route == 'MainPage'){
        status = true
      }
      else if (route == 'Login'){
        status = true
      }
      else if (route == 'ForgetPassword'){
        status = true
      }
      else{
        status = false
      }
     // console.log(this.$route.name)
     // console.log(status)
      return status;
    }
  }
}
</script>

<style>
:root{
    --main: rgba(126, 166, 67,1);
    --main-hover: rgb(96, 129, 47);
}
.footer{
  z-index: 1000;
  padding: 10px !important; 
  border-top: solid 2px rgba(0,0,0,.15) !important;
}

#app{
    background-color: #f2f2f2 !important;
    z-index: 0;
}
.side-nav-adjustment{
    z-index: 0;
    box-shadow: 2px 2px 10px rgba(0,0,0,.35);
    background-color: rgba(255,255,255,0);
    position: relative;
    top: 0;
    left: 0;
    min-width: 325px;
    height: 100%;
    transition: all .3s ease;
}
.side-nav{
    z-index: 2;
    /* display: flex; */
    box-shadow: 2px 2px 10px rgba(0,0,0,.35);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 325px;
    width: 325px;
    height: 100%;
}
.side-nav-container{
    padding: 100px 25px 20px 25px;
    width: 100%;
}
.mobile-side-nav{
    transition: all .25s ease;    
}
.mobile-side-nav .mobile-button{
    position: absolute;
    top: 120px;
    left: 0;
    padding: 10px;
    background-color: rgba(0,0,0,.25);
    z-index: 2000;
    box-shadow: 2px 2px 10px rgba(0,0,0,.1);
    opacity: .5;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
    transition: all .25s ease;    
}
.mobile-side-nav .mobile-button.close{
    transform: translate(-60px,0);
}
.mobile-side-nav .mobile-button:hover{
    opacity: 1;
}
.mobile-side-nav .mobile-button i{
    background-color: rgba(255, 255, 255,.8);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0,0,0,.1);;
    font-size: 20px;
}
.mobile-side-nav .mobile-option .side-nav{
    z-index: 2100;
    transition: all .25s ease;
}
.mobile-side-nav .mobile-option .overlay{
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.75);
    transition: all .1s ease;
}
.mobile-side-nav .mobile-option .overlay i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
}
.mobile-option.close .overlay{
    opacity: 0;
    pointer-events: none;
}
.mobile-option.close .side-nav{
    transform: translate(-100% , 0);
    pointer-events: none;
}

.user-info{
    margin-top: 40px;
}

.body-content{
    width: 100%;
    position: relative;
}
.dashboard-body-content-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d4d4d4;
    background: url(./assets/dashboard-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .25;
    z-index: -1000;
}
.body-content .body-container{
    width: 100%;
    padding: 50px 12em;
}
@media (max-width: 1600px) {
    .body-content .body-container{
        padding: 50px 50px !important;
    }
}

.dashboard-content-card{
    position: relative;
    overflow: hidden;
    background-color: rgba(255, 255, 255,.8);
    border-radius: 4px;
    box-shadow: 2px 2px 10px rgba(0,0,0,.15);
    padding: 40px 50px;
    height: 100%;
}
.v-expansion-panel-content .v-expansion-panel-content__wrap{
    padding-bottom: 0 !important;
}
.sub-options{
    transition: background-color .3s ease;
}
.sub-options:hover{
    background-color: #d4d4d4;
}
button.btn-primary:focus{
    background-color: #094092 !important;
}
.mobile-logout{
    margin: 75px 0 50px 0;
}
.mobile-logout button {
    padding: 20px 40px;
}

.hide-nav-menu{
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 1000;
    background: rgba(255,255,255,1);
    left: 325px;  
    top: 100px;  
    border-radius: 0 4px 4px 0;
    box-shadow: 5px 4px 10px rgba(0,0,0,.15);
    cursor: pointer !important;
    font-size: 22px;
    transition: all .2s ease;
    transform-origin: left;
    transform: translate(0, 25px)
                scaleX(0);
    opacity: 0;
    pointer-events: none;
}
.hide-nav-menu.show{
    transform: translate(0, 25px)
                scaleX(1);
    opacity: .3;
    pointer-events: auto;
}
.hide-nav-menu.show.close{
    transform: translate(-325px, 25px)
                scaleX(1);
}
.hide-nav-menu.show:hover{
    opacity: .75;
}
.hide-nav-menu i{
    transition: transform .3s ease;
}
.hide-nav-menu.close i{
    transform: rotateZ(180deg);
}
.side-nav-adjustment.close{
    position: fixed;
    transform: translate(-100%,0);
}

input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field{
  position: absolute !important;
  border-left:1px solid #8c8c8c;
  padding-left: 8px;
  color:#000;
  left: 76px;
  margin-left: 5px;
}

input[type="date"]::-webkit-datetime-edit-month-field{
  position: absolute !important;
  border-left:1px solid #8c8c8c;
  padding-left: 8px;
  color:#000;
  left: 35px;
  margin-left: 5px;
}


input[type="date"]::-webkit-datetime-edit-day-field{
  position: absolute !important;
  color:#000;
  padding-left: 5px;
  left: 5px;
  
}
</style>

