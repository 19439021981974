<template>
<section id="home">
    <div id="homeCarousel">
        <div class="inner-carousel position-relative w-100 h-100">
            <div class="carousel-overlay"></div>
            <div class="first-slide">
                <div class="slide-text">
                    <div><h1><b>Welcome to <br> Malaysia Paediatric Resuscitation Society</b></h1></div>
                    <div>
                        <a href="#events">
                            <div class="text-bg shadow btn-check-out">
                                <div class="mb-0">Check Out Our Event Now</div>
                            </div>
                        </a>     
                    </div>              
                </div>                
            </div>
        </div>
    </div>
    <!-- ===================================================== Venue -->
    <div id="events" class="section-title shadow under-carousel">
        <h1><b>Events</b></h1>
    </div>
    <div class="container mb-5">
        <div class="row g-2 mx-auto" style="width:98%">
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-for="(data, index) in datas" :key="index" >
                <div class="event-box">
                    <div id="event-0" class="event-photo"></div>
                    <h5><b>{{data.name}} ({{data.event_type}})</b></h5>                
                    <hr>
                    <p><b>Date: </b>{{data.start_date}}  -  {{data.end_date}}</p>                                
                    <p><b>Time: </b>{{data.start_time}}  -  {{data.end_time}}</p>                                
                    <p><b>Venue: </b>{{data.venue}}</p>       
                    <hr>
                    <div>
                        <a @click="event_detail(data.ID)">
                            <div class="btn-main" >
                                More Detail    
                            </div>
                        </a>
                    </div>  
                </div>                                       
            </div>
        </div>
    </div>
            
    
</section>
</template>

<script>
import axios from 'axios';
import store from '../store';
export default {
    data() {
        return {
            url: "",
            datas: []
        };
    },
    methods: {        
        event_detail(id){
            store.commit("EventID",id)
            console.log(id)
            this.$router.push({ name: 'Events', params: { id } })      
        }
    },
    created() {
        this.url = this.WebUrl + "getAllEvent.php"
        axios.get(this.url).then(resp => {
            this.datas = resp.data;    
        });        
    },
    mounted(){
    },
    updated(){
    },
    computed: {
        WebUrl() {
            return this.$store.state.web_url;
        },
        EventID(){
            return this.$store.state.event_id;
        }
    }
};
</script>
<style>

*{
    box-sizing: border-box;
}
#homeCarousel{
    background-color: grey;
    height: 750px;
    overflow: hidden;
}
.inner-carousel{
    animation: zoomOut 1s both ease-out;
}
.carousel-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .3;
    z-index: 1;
}
.first-slide{
    position: absolute;
    background: url(../assets/carousel01.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0;        
}
.first-slide .slide-text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    z-index: 2;
    text-shadow: 2px 2px 7px rgba(0, 0, 0,.75);
    transform: translate(-50%, -50%);
}
.first-slide .slide-text div:nth-child(1){
    animation: fadeUp 1s 1s ease both;
}
.first-slide .slide-text div:nth-child(2){
    animation: fadeUp 1s 1.5s ease both;
}
.first-slide .slide-text div:nth-child(3){
    animation: fadeUp 1s 2s ease both;
}
.first-slide .slide-text .text-bg{
    background-color: var(--main);
    width: fit-content;
    padding: 5px 30px;
    border-radius: 20px 0;
    margin: 0 5px 7px 5px;
}
.first-slide .slide-text .text-bg.btn-check-out{
    cursor: pointer;
    padding: 10px 30px;
}
.first-slide .slide-text a .text-bg.btn-check-out{
    color: #fff;
    transition: background-color .3s ease;
}
.first-slide .slide-text a .text-bg.btn-check-out:hover{
    background: var(--main-hover);
}
.section-title{
    position: relative;
    background-color: var(--main);
    padding: 25px 100px;
    border-radius: 0 0 100px 0;
    color: #fff;
    left: -25%;
    z-index: 5;
}
.section-title.under-carousel{    
    top: -30px;    
}
.section-title h1{
    position: relative;
    padding-left: 40%;
}
.event-box{
    overflow: hidden;
    padding: 230px 25px 30px 25px;
    border: 1px solid rgba(0, 0, 0,.35);
    border-radius: 20px 0;
    position: relative;
}
.event-photo{
    background: url(../assets/event/event-2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
}
#event-1{
    background: url(../assets/event/event-1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
a .btn-main{
    text-decoration: none;
    color: #fff;
}
.btn-main{
    background-color: var(--main);
    width: fit-content;
    padding: 10px 30px;
    border-radius: 20px 0;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    transition: background-color .3s ease;
}
.btn-main:hover{
    background-color: var(--main-hover);
}
.participants-bg{
    position: relative;
    width: 100%;
    height: auto;
    padding: 25px 0;
    background-color: #969696;
    background: url(../assets/participants-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    top: -30px;
}
.participants-bg .overlay{
    background-color: #fff;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.participants-bg .container{
    position: relative;
    z-index: 2;
    padding: 40px 0;
}
.content-card{
    background-color: rgb(250, 250, 250);
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0,0,0,.25) ;
    max-width: 400px;
    padding: 40px 25px;
    height: 100%;
}
.home-footer{
    position: relative;
    background-color: var(--main);
    padding: 50px 0;
    margin-top: -30px;
}
.footer-icon i{
    font-size: 40px;
}
ul.footer-list li{
    list-style-type: disc !important;
}
.footer-box{
    width: fit-content;
    padding: 10px 30px;
}

</style>