import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {

    isLoggedIn: false,
    Email: "",
    event_id: "",
    web_url: "https://www.mprs.com.my/php_script/",
    name : ""
  },
  getters: {
    
  },
  mutations: {
    IsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    Email(state, data) {
      state.Email = data;
    },
    EventID(state, data) {
      state.event_id = data;
    },
    Name(state,data) {
      state.name = data;
    },
    WebUrl(state,data) {
      state.web_url = data;
    }
   
  },
  actions: {

  },
  plugins: [new VuexPersistence().plugin]

})
export default store