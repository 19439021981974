<template>
<div class="body-content d-block" style="width: auto; overflow: hidden;">
    <div class="dashboard-body-content-bg"></div>
    <!-- ===== Content ===== -->
    <div class="body-container" style="padding: 0 !important;">
        <div class="row mx-auto">
            <div class="col-12">
                <div class="dashboard-content-card text-center">
                    <vue-element-loading :active="show" is-full-screen />
                    <div style="text-align:center">
                        <h2>List of Instructors</h2>
                    </div>
                    <div>
                        Total Active Instructors : {{ totalRecords }}
                    </div>
                    <hr>
                    <div class="input-container">
                        <div class="inner-container">
                            <input type="text" v-model="searchQuery">
                            <button><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                    <hr>
                    <div class="dashboard-table">
                        <div class="table-responsive" v-if="datas.length > 0">
                            <table class="table-striped table-bordered">
                                <thead>
                                    <tr>
                                    <th>No</th>
                                    <th>Name</th>
                                    <th>Date of GIC</th>
                                    <th>Status</th>
                                    <th>Country</th>
                                    <th>State</th>
                                    <th>Workplace</th>
                                    <th>Phone Number</th>
                                    <th>Still committed to APLS/PLS</th>
                                    <th>Last Course</th>
                                    <th>Email Address</th>
                                    <th>Total APLS course</th>
                                    <th>Total PLS course</th>
                                    <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, index) in filteredDatas" :key="index">
                                    <td>{{ index + 1 + (currentPage - 1) * pageSize }}</td>
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.gic_date }}</td>
                                    <td>{{ data.status }}</td>
                                    <td>{{ data.country }}</td>
                                    <td>{{ data.place_state }}</td>
                                    <td>{{ data.workplace }}</td>
                                    <td>{{ data.phone }}</td>
                                    <td>{{ data.comittee_status }}</td>
                                    <td>{{ data.last_course_date }}</td>
                                    <td>{{ data.email }}</td>
                                    <td>{{ data.apls_count }}</td>
                                    <td>{{ data.pls_count }}</td>
                                    <td>
                                        <!-- Assuming you have a method to trigger for edit -->
                                        <v-btn depressed color="blue" dark @click="editInstructor(data)">Edit</v-btn>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <div class="row w-100 pb-5">
                                <pagination class="pt-4" :records="totalRecords" :per-page="pageSize" v-model="currentPage" />
                            </div>
                        </div>
                        <div v-else>
                            <p>No data available.</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>  <!-- <=== End Content  -->
</div>
</template>

<script>
import axios from 'axios';
import VueElementLoading from 'vue-element-loading'
import Pagination from 'vue-pagination-2';

export default {
    name: "Instructors",
    components: {

        VueElementLoading,
        Pagination
    },
    data() {

        return {
            datas: [],
            searchQuery: '',
            currentPage: 1,
            pageSize: 5,
            name: "",
            gic_date: "",
            status: "",
            country: "",
            place_state: "",
            workplace: "",
            phone: "",
            comittee_status: "",
            last_course_date: "",
            email: "",
            isLoading: false,
            fullPage: true,
            show: false,
            label: 'Please wait for the data loading...',
            url: ""
           
        };
    },
    methods: {
        addInstructors() {

            if (this.name != '') {
                axios.post(this.url, {
                        request: 3,
                        name: this.name,
                        gic_date: this.gic_date,
                        status: this.status,
                        country: this.country,
                        place_state: this.place_state,
                        workplace: this.workplace,
                        phone: this.phone,
                        comittee_status: this.comittee_status,
                        last_course_date: this.last_course_date,
                        email: this.email,
                        created_by: this.UserEmail

                    })
                    .then(response => {
                        this.name = ""
                        this.gic_date = ""
                        this.status = ""
                        this.country = ""
                        this.place_state = ""
                        this.workplace = ""
                        this.phone = ""
                        this.comittee_status = ""
                        this.last_course_date = ""
                        this.email = ""

                        this.show_instructors();
                        console.log(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                alert('Fill all fields.');
            }

        },
        editInstructors(index, id) {
            // update meetings in firestores

            axios.post(this.url, {
                    request: 2,
                    id: id,
                    name: this.datas[index].name,
                    gic_date: this.datas[index].gic_date,
                    status: this.datas[index].status,
                    country: this.datas[index].country,
                    place_state: this.datas[index].place_state,
                    workplace: this.datas[index].workplace,
                    phone: this.datas[index].phone,
                    comittee_status: this.datas[index].comittee_status,
                    last_course_date: this.datas[index].last_course_date,
                    email: this.datas[index].email,
                    user: this.datas[index].UserEmail
                })
                .then(response => {
                    console.log(response.data);
                    this.show_instructors();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        show_instructors() {
            this.show = true
            axios.post(this.url, {
                    request: 1,
                })
                .then(response => {
                    //console.log(response.data)
                    this.datas = response.data;
                    this.show = false;
                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
       
        
    },
    created() {
        this.url = this.WebUrl + "instructors.php"
        this.show_instructors()
      
    },

    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        WebUrl() {
            return this.$store.state.web_url;
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.datas.slice(start, end);
        },
        totalRecords() {
            return this.datas.length;
        },
        filteredDatas() {
            if (!this.searchQuery.trim()) {
                return this.paginatedData;
            }
            const searchLower = this.searchQuery.toLowerCase();
            return this.paginatedData.filter((data) =>
                data.name.toLowerCase().includes(searchLower) ||
                data.email.toLowerCase().includes(searchLower)
            );
        },

    }

};
</script>

<style>
.v-select {
    display: grid;
}
.dashboard-table{
    overflow: auto;
    min-height: fit-content;
}
.table-responsive{
    padding-block: 2rem;
    min-height: fit-content;
}
.dashboard-table th{
    width: auto !important;
}
.flipped, .flipped .content
{
    transform:rotateX(180deg);
    -ms-transform:rotateX(180deg); /* IE 9 */
    -webkit-transform:rotateX(180deg); /* Safari and Chrome */
}
nav {
    background-color: #fff !important; /* Or any specific shade of green you prefer */
    box-shadow: none !important;
    /* color: white;  */
}
.pagination li.active{
    background-color: rgb(255 255 255 / 0) !important; /* Or any specific shade of green you prefer */
    /* color: white;  */
}
.VuePagination p {
    background-color: rgb(255 255 255 / 0);
    color: #000 !important;
}
.input-container{
    padding-top: 2rem;
    padding-inline: 1.5rem;
    max-width: 350px;
    height: fit-content;
}
.input-container .inner-container{
    display: flex;
}
.input-container button{
    border-radius: 0 8px 8px 0;
    padding: .25rem 1rem;
    outline: 1px solid rgb(0 0 0 / .25) !important;
    height: 100%;
    cursor: pointer;
    height: 50px !important;
}
.input-container input{
    padding: 0rem 1rem !important;
    height: 50px !important;
    border-radius: 8px 0 0 8px !important;
    outline: 1px solid rgb(0 0 0 / .25) !important;
    margin: 0 !important;
}
.VuePagination p{
    background-color: white !important;
}
</style>
