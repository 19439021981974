<template>
<div class="navbar py-0 shadow nav-fixed">
    <nav class="nav-extended" style="background-color:white !important">
        <div class="nav-content d-flex">
            <div class="nav-container">
                <div class="row">
                    <div class="col-6 d-flex">
                        <router-link :to="{ name: 'MainPage' }" class="my-auto">
                            <img src="../assets/logo.jpg" style="max-height:80px">
                        </router-link>
                    </div>
                    <div class="col-6 d-flex">
                        <v-toolbar-title class="ms-auto">
                            <v-btn text large color="black" height="100" v-if="isLoggedIn" v-on:click="home"><i class="bi bi-clipboard-data-fill me-2"></i> Dashboard</v-btn>
                            <v-btn text large color="black" height="100" v-if="!isLoggedIn" v-on:click="login">Login</v-btn>
                            <v-btn text large color="black" height="100" v-if="isLoggedIn" v-on:click="logout">Logout</v-btn>
                        </v-toolbar-title>
                    </div>
                </div>
            </div>
            <v-row>
                <v-col>
                    
                </v-col>
                
            </v-row>
        </div>
    </nav>
</div>
</template>

<script>
import store from "../store";

export default {
    name: "Navbar",
    data() {
        return {
            cn: false,
        };
    },

    methods: {
        logout: function () {
            store.commit("IsLoggedIn", false);
            store.commit("Email", "");
            this.$router.push("/login");

        },
        home: function () {
            this.$router.push("/home");

        },
        login: function () {
            this.$router.push("/login");
        },
        async setupNav() {
            const navbar = await document.querySelector('.navbar');
            document.addEventListener('scroll',()=>{
                const axisY =  window.pageYOffset;
                if (axisY<1) {
                    navbar.classList.remove('nav-fixed')
                } else {
                    if (!navbar.classList.contains('nav-fixed')) {
                        navbar.classList.add('nav-fixed')
                    }
                }
            })
        },
    },
    created() {

    },
    computed: {
        UserEmail() {
            return this.$store.state.Email;
        },
        isLoggedIn() {
            return this.$store.state.isLoggedIn;
        },

    },
    mounted(){
        // this.setupNav();
    },
};
</script>

<style>
.btn {
    font-weight: bold;
}
.nav-container{
    padding: 0px 12%;
    width: 100%;
}
button:focus{
    background-color: rgb(226, 226, 226,.01) !important;
}
.navbar{
    z-index: 1000;
}
.nav-fixed{
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0%;
}
</style>
